/* eslint-disable */
import React, { useEffect, useState } from 'react'
import Profile from './homeComp/Profile'
import Cards from './homeComp/Cards'
import SwitchComp from './homeComp/SwitchComp'
import { useDispatch, useSelector } from 'react-redux'
import { permissionTypes } from 'types/permissionTypes'
import Chart from './homeComp/Chart'
import { useRequest } from 'hooks/useRequest'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import images from 'assets/images/images'
import Table from 'components/common/table/Table'
import Button from 'components/common/Button'
import { downloadFileFromStream } from 'utils/ExcelOutput'
import {Tooltip} from 'react-tooltip';



const Home = () => {
	const [request, loading, error] = useRequest()
	const [switchOpen, setSwitchOpen] = useState(false)
	const { t, i18n } = useTranslation()
	const dispatch = useDispatch();

	const { permissions } = useSelector((state) => state.configuration)
	const { currentUser } = permissions.configurations


	const [reportCommonStats, setReportCommonStats] = useState()
    const [lineFilter, setLineFilter] = useState(0);
    const [pieceAndAmountFilter, setPieceAndAmountFilter] = useState(0);
    const [dateFilter, setDateFilter] = useState(
		{
			startDate: moment().subtract(1, 'months').format('YYYY-MM-DD'),
			endDate: moment().format('YYYY-MM-DD'),
		}
	);

	const [chartData1, setChartData1] = useState({
		series: [],
		options: {
			chart: {
				height: 450,
				type: 'line',
				zoom: {
					enabled: false,
				},
			},
			dataLabels: {
				enabled: true,
			},
			stroke: {
				curve: 'straight',
			},
			// yaxis: {
			// 	labels: {
			// 		formatter: function (val) {
			// 			return Math.round(val);
			// 		},
			// 	},
			// 	//tickAmount: 3, // optional: adjust based on your specific range
			// },
			title: {
				text: t('gifts'),
				align: 'left',
			},
			grid: {
				row: {
					colors: ['#fff', '#fff'], // takes an array which will be repeated on columns
					opacity: 0.5,
				},
			},
		},
	})
	const [chartData2, setChartData2] = useState({
		series: [],
		  options: {
			chart: {
			  type: 'bar',
			  //height: 100 * chartData3?.series?.length
			},
			title: {
				text: t('giftType'),
				align: 'left',
			},
			plotOptions: {
			  bar: {
				horizontal: true,
				//columnWidth: 400,
				endingShape: 'rounded'
			  },
			},
			dataLabels: {
			  enabled: true
			},
			stroke: {
			  show: true,
			  width: 2,
			  colors: ['transparent']
			},
		  },
	})
	const [chartData3, setChartData3] = useState({
		series: [],
		  options: {
			chart: {
			  type: 'bar',
			  //height: 100 * chartData3?.series?.length
			},
			title: {
				text: t('purposeoftheGift'),
				align: 'left',
			},
			plotOptions: {
			  bar: {
				horizontal: true,
				//columnWidth: 400,
				endingShape: 'rounded'
			  },
			},
			dataLabels: {
			  enabled: true
			},
			stroke: {
			  show: true,
			  width: 2,
			  colors: ['transparent']
			},
		  },
	})
	const [chartData4, setChartData4] = useState({
		series: [],
		  options: {
			chart: {
			  type: 'bar',
			  //height: 100 * chartData3?.series?.length
			},
			title: {
				text:  t('relatedPartyType'),
				align: 'left',
			},
			plotOptions: {
			  bar: {
				horizontal: true,
				//columnWidth: 400,
				endingShape: 'rounded'
			  },
			},
			dataLabels: {
			  enabled: true
			},
			stroke: {
			  show: true,
			  width: 2,
			  colors: ['transparent']
			},
		  },
	})
	const [chartData5, setChartData5] = useState({
		series: [],
		  options: {
			chart: {
			  type: 'bar',
			  //height: 550
			},
			title: {
				text:  t('reasonsforRefusal'),
				align: 'left',
			},
			plotOptions: {
			  bar: {
				horizontal: true,
				//columnWidth: '55%',
				endingShape: 'rounded'
			  },
			},
			dataLabels: {
			  enabled: true
			},
			stroke: {
			  show: true,
			  width: 2,
			  colors: ['transparent']
			},
		  },
	})

	const [getGiftLimit, setGetGiftLimit] = useState({});
	const [giftLimitFilterParameters, setGiftLimitFilterParameters] = useState({});

	const [filters, setFilters] = useState({
		search: "",
		year: 2024,			
		sortingOrderByVal: "desc",
		sortingColumnName: "",
		page: 1,
		onlyLimitExceeded: false
	})

	useEffect(() => {
		// ReportPeriod=0&
		request(
			`/report/gift-stats-for-period?ReportPeriod=${lineFilter}&OnlyOwnRecords=${switchOpen}`,
			{
				method: 'GET',
			}
		).then((res) => {
			if (res.data.ok) {
        setChartData1(prev => ({...prev, 
          series: pieceAndAmountFilter == 0 ? [
            {
              name: t('incomingGifts'),
              data: res.data.result.map(r => r?.incomingGiftCount) //[10, 41, 35, 51, 49, 62, 69, 91, 148],
            },
            {
              name: t('outgoingGifts'),
              data: res.data.result.map(r => r?.outgoingGiftCount) //[14, 45, 15, 71, 19, 22, 39, 41, 48],
            },
          ] : [
            {
              name: t('incomingGifts'),
              data: res.data.result.map(r => r?.incomingGiftAmount) 
            },
            {
              name: t('outgoingGifts'),
              data: res.data.result.map(r => r?.outgoingGiftAmount) 
            },
          ],
		  options: {
			xaxis: {
				title: {
					text: t('date'),
				},
				categories:  res.data.result.map(r => r?.summary),
			},
			yaxis: {
				title: {
					text: pieceAndAmountFilter == 0 ? t('piece') : t('Amount'),
				},
				labels: {
					formatter: function (val, index) {
						return  pieceAndAmountFilter == 0 ? val : "$" + val;
					},
				},
				tickAmount:  res?.data?.result?.length + 1, // optional: adjust based on your specific range
			},
		  }	
        }))
				
			}
		})
	}, [switchOpen, lineFilter, pieceAndAmountFilter])

  useEffect(() => {
		request(
			`/report/gift-stats-by-gift-type?OnlyOwnRecords=${switchOpen}&StartDate=${
				dateFilter?.startDate ? moment(dateFilter?.startDate).format('YYYY-MM-DD') : ''
			}&EndDate=${
				dateFilter?.endDate ? moment(dateFilter?.endDate).format('YYYY-MM-DD') : ''
			}`,
			{
				method: 'GET',
			}
		).then((res) => {
			if (res.data.ok) {
        setChartData2(prev => ({...prev, 
          series: pieceAndAmountFilter == 0 ? [
            {
			  name: t('incomingGifts'),
              data: res.data.result.map(r => r?.incomingGiftCount) //[10, 41, 35, 51, 49, 62, 69, 91, 148],
            },
            {
			  name: t('outgoingGifts'),
              data: res.data.result.map(r => r?.outgoingGiftCount) //[14, 45, 15, 71, 19, 22, 39, 41, 48],
            },
          ] : [
            {
			  name: t('incomingGifts'),
              data: res.data.result.map(r => r?.incomingGiftAmount) //[10, 41, 35, 51, 49, 62, 69, 91, 148],
            },
            {
			  name: t('outgoingGifts'),
              data: res.data.result.map(r => r?.outgoingGiftAmount) //[14, 45, 15, 71, 19, 22, 39, 41, 48],
            },
          ],
		  options: {
			xaxis: {
				categories:  res.data.result.map(r => r?.summary),
				//stepSize:  res.data.result.length,
				tickAmount:  res?.data?.result?.length + 1,
				title: {
					text: pieceAndAmountFilter == 0 ? t('piece') : t('Amount'),
				},
				labels: {
					formatter: function (val, index) {
						return  pieceAndAmountFilter == 0 ? val : "$" + val;
					},
				},
			}
		  } 
        }))
				
			}
		})

		request(
			`/report/gift-stats-by-gift-purpose?OnlyOwnRecords=${switchOpen}&StartDate=${
				dateFilter?.startDate ? moment(dateFilter?.startDate).format('YYYY-MM-DD') : ''
			}&EndDate=${
				dateFilter?.endDate ? moment(dateFilter?.endDate).format('YYYY-MM-DD') : ''
			}`,
			{
				method: 'GET',
			}
		).then((res) => {
			if (res.data.ok) {
        setChartData3(prev => ({...prev, 
          series:  pieceAndAmountFilter == 0 ? [
            {
			  name: t('incomingGifts'),
              data: res.data.result.map(r => r?.incomingGiftCount) //[10, 41, 35, 51, 49, 62, 69, 91, 148],
            },
            {
			  name: t('outgoingGifts'),
              data: res.data.result.map(r => r?.outgoingGiftCount) //[14, 45, 15, 71, 19, 22, 39, 41, 48],
            },
          ] : [
            {
			  name: t('incomingGifts'),
              data: res.data.result.map(r => r?.incomingGiftAmount) //[10, 41, 35, 51, 49, 62, 69, 91, 148],
            },
            {
			  name: t('outgoingGifts'),
              data: res.data.result.map(r => r?.outgoingGiftAmount) //[14, 45, 15, 71, 19, 22, 39, 41, 48],
            },
          ],
		  options: {
			xaxis: {
				categories:  res.data.result.map(r => r?.summary),
				tickAmount:  res?.data?.result?.length ,
				title: {
					text: pieceAndAmountFilter == 0 ? t('piece') : t('Amount'),
				},
				labels: {
					
					formatter: function (val, index) {
						return  pieceAndAmountFilter == 0 ? val : "$" + val;
					},
				},
			},
			yaxis: {
				categories: []
				
			}
		  }	 
        }))
				
			}
		})

		request(
			`/report/gift-stats-by-gift-related-party-type?OnlyOwnRecords=${switchOpen}&StartDate=${
				dateFilter?.startDate ? moment(dateFilter?.startDate).format('YYYY-MM-DD') : ''
			}&EndDate=${
				dateFilter?.endDate ? moment(dateFilter?.endDate).format('YYYY-MM-DD') : ''
			}`,
			{
				method: 'GET',
			}
		).then((res) => {
			if (res.data.ok) {
        setChartData4(prev => ({...prev, 
          series:pieceAndAmountFilter == 0 ? [
            {
			  name: t('incomingGifts'),
              data: res.data.result.map(r => r?.incomingGiftCount) //[10, 41, 35, 51, 49, 62, 69, 91, 148],
            },
            {
			  name: t('outgoingGifts'),
              data: res.data.result.map(r => r?.outgoingGiftCount) //[14, 45, 15, 71, 19, 22, 39, 41, 48],
            },
          ] : [
            {
			  name: t('incomingGifts'),
              data: res.data.result.map(r => r?.incomingGiftAmount) //[10, 41, 35, 51, 49, 62, 69, 91, 148],
            },
            {
			  name: t('outgoingGifts'),
              data: res.data.result.map(r => r?.outgoingGiftAmount) //[14, 45, 15, 71, 19, 22, 39, 41, 48],
            },
          ],
		  options: {
			xaxis: {
				categories:  (res.data.result.map(r => r?.summary)),
				tickAmount:  res?.data?.result?.length + 1,
				title: {
					text: pieceAndAmountFilter == 0 ? t('piece') : t('Amount'),
				},
				labels: {
					
					formatter: function (val, index) {
						return  pieceAndAmountFilter == 0 ? val : "$" + val;
					},
				},
			},
			yaxis: {
				categories: []
			}
		  }	 
        }))
				
			}
		})

		request(
			`/report/gift-stats-by-gift-reject-reason?OnlyOwnRecords=${switchOpen}`,
			{
				method: 'GET',
			}
		).then((res) => {
			if (res.data.ok) {
        setChartData5(prev => ({...prev, 
			series:pieceAndAmountFilter == 0 ? [
				{
				  name: t('incomingGifts'),
				  data: res.data.result.map(r => r?.incomingGiftCount) //[10, 41, 35, 51, 49, 62, 69, 91, 148],
				},
				{
				  name: t('outgoingGifts'),
				  data: res.data.result.map(r => r?.outgoingGiftCount) //[14, 45, 15, 71, 19, 22, 39, 41, 48],
				},
			  ] : [
				{
				  name: t('incomingGifts'),
				  data: res.data.result.map(r => r?.incomingGiftAmount) //[10, 41, 35, 51, 49, 62, 69, 91, 148],
				},
				{
				  name: t('outgoingGifts'),
				  data: res.data.result.map(r => r?.outgoingGiftAmount) //[14, 45, 15, 71, 19, 22, 39, 41, 48],
				},
			  ],
		  options: {
			xaxis: {
				title: {
					text: pieceAndAmountFilter == 0 ? t('piece') : t('Amount'),
				},
				tickAmount:  res?.data?.result?.length + 1,
				labels: {
					
					formatter: function (val, index) {
						return  pieceAndAmountFilter == 0 ? val : "$" + val;
					},
				},
				categories:  res.data.result.map(r => r?.summary),
			},
			yaxis: {
				categories: []
			}
		  }	 
        }))
				
			}
		})

	}, [switchOpen, dateFilter, pieceAndAmountFilter])



	const COLUMNS = [
		{
			label: t('companyName'),
			renderCell: (item) =>  <div style={{display: "flex", alignItems: "center", gap: "5px"}}>
			{item.companyName}
			{item.subCompaniesIncludedToLimit && 
			// <img title={t('subsidiariesIncludedInLimit')} src={images.warningRounded} alt='' />
            <>
			<img
				data-tooltip-id="tooltip-info"
				data-tooltip-content={
				`${t('subsidiariesIncludedInLimit')}`
				}
				src={images.warningRounded}
				alt=''
			/>
			<Tooltip id="tooltip-info" style={{ maxWidth: '100%' }} />
			</>
			}
			{item.usingParentCompanyLimit && 
			// <img title={t('usingParentCompanyLimit')} src={images.warningRounded} alt='' />
             <>
			<img
				data-tooltip-id="tooltip-info"
				data-tooltip-content={
				`${t('usingParentCompanyLimit')}`
				}
				src={images.warningRounded}
				alt=''
			/>
			<Tooltip id="tooltip-info" style={{ maxWidth: '100%' }} />
			</>
			}
		</div>,
			name: 'companyName',
		},
		{
			label: t('giftRelatedOrganizationName'),
			renderCell: (item) => item.targetCompanyName,
			name: 'targetCompanyName',
		},
		// {
		// 	label: t('year'),
		// 	renderCell: (item) => item.year,
		// 	name: 'year',
		// },
		// {
		// 	label: t('CurrencyUnit'),
		// 	renderCell: (item) => item.currencyCode,
		// 	name: 'currencyCode',
		// },
		// renderCell: (item) => new Intl.NumberFormat(i18n.language, { style: 'currency', currency: item.currencyCode }).format(item.amount),
		{
			label: t('incomingLimit'),
			renderCell: (item) => new Intl.NumberFormat(i18n.language, { style: 'currency', currency: item.currencyCode }).format(item?.incomingLimit),
			name: 'incomingLimit',
		},
		{
			label: t('incomingLimitRemaining'),
			renderCell: (item) =>
				new Intl.NumberFormat(i18n.language, { style: 'currency', currency: item.currencyCode }).format(item?.remainingIncomingLimit),
			name: 'remainingIncomingLimit',
		},
		{
			label: t('outgoingLimit'),
			renderCell: (item) => new Intl.NumberFormat(i18n.language, { style: 'currency', currency: item.currencyCode }).format(item?.outgoingLimit),
			name: 'outgoingLimit',
		},
		{
			label: t('outgoingLimitRemaining'),
			renderCell: (item) =>
				new Intl.NumberFormat(i18n.language, { style: 'currency', currency: item.currencyCode }).format(item?.remainingOutgoingLimit),
			name: 'remainingOutgoingLimit',
		},
		// {
		// 	label: t('generalManagerApproval'),
		// 	renderCell: (item) =>
		// 		item.isGeneralManagerApprovalRequired ? <AiOutlineCheckSquare  color='green' size={27}/> : <AiOutlineCloseSquare  size={27} color='#c1312f'/>,
		// 	name: 'isGeneralManagerApprovalRequired',
		// },
		// {
		// 	label: t('isManagerApprovalRequiredForIncoming'),
		// 	renderCell: (item) =>
		// 		item.isManagerApprovalRequiredForIncoming ? <AiOutlineCheckSquare  color='green' size={27}/> : <AiOutlineCloseSquare  size={27} color='#c1312f'/>,
		// 	name: 'isManagerApprovalRequiredForIncoming',
		// },
		// {
		// 	label: '',
		// 	renderCell: (item) => (
		// 		<div className='lastCellFlex'>
		// 			{currentUser?.permissions[permissionTypes.CompanyGiftLimitsUpsert] && <div className='editBtn' onClick={(e) => e.stopPropagation()}>
		// 				<FaRegEdit size={15} onClick={() => handleEdit(item)} />
		// 				<div onClick={() => handleEdit(item)} className=''>
		// 				{t('edit')}
		// 				</div>
		// 			</div>}
		// 			{currentUser?.permissions[permissionTypes.CompanyGiftLimitsDelete] && <div className='deleteBtn' onClick={(e) => e.stopPropagation()}>
		// 				<MdOutlineDeleteSweep size={18} onClick={() => handleRemove(item)} />
		// 				<div onClick={() => handleRemove(item)} className=''>
		// 				{t('delete')}
		// 				</div>
		// 			</div>}
		// 		</div>
		// 	),
		// 	name: '',
		// },
	]


	useEffect(() => {
        setFilters(prev => ({...prev, page: 1}))
	}, [filters.search, filters.year, filters.sortingColumnName, filters.sortingOrderByVal])

	useEffect(() => {
		request(`/report/gift-limits?Page=${filters?.page}&OnlyLimitExceeded=${filters?.onlyLimitExceeded == ""  ? false : filters?.onlyLimitExceeded}&Search=${filters.search}&Year=${filters.year}&OrderBy=${filters.sortingColumnName ? filters.sortingColumnName + " "+ filters.sortingOrderByVal : ""}`, {
			method: 'GET',
		}).then((res) => {
			console.log(res, "res");

			if (res.data.ok) {

				let filterPrm = {
					//buraya yeni parametreler eklenecek ...
					companyId: res.data.result.companyId,
					year: res.data.result.year,
					pageIndex: res.data.result.pageIndex,
					pageSize: res.data.result.pageSize,
					totalCount: res.data.result.totalCount,
					totalPages: res.data.result.totalPages,
					indexFrom: res.data.result.indexFrom,
				}
				//dispatch(getGiftLimitFilterParametersFunc(filterPrm))
				//dispatch(getGiftLimit(res.data.result.items))
				setGiftLimitFilterParameters(filterPrm)
				setGetGiftLimit(res.data.result.items)
			}
		})
	}, [filters])

	let nodes = getGiftLimit

	let data = { nodes }

	const handleDownloadXlsx = () => {
		request(`/report/gift-limits/export-excel?Page=${filters?.page}&OnlyLimitExceeded=${filters?.onlyLimitExceeded == ""  ? false : filters?.onlyLimitExceeded}&Year=${filters.year}&OrderBy=${filters.sortingColumnName ? filters.sortingColumnName + " "+ filters.sortingOrderByVal : ""}`, {
			method: 'GET',
		}).then((res) => {
			if (res.data.ok) {
				downloadFileFromStream('gift-limit.xlsx', res.data.result)
			}
		})
	}

	const goToPrevPage = () => {
		if (giftLimitFilterParameters?.pageIndex == 0) return
		setFilters(prev => ({...prev, page: giftLimitFilterParameters?.pageIndex - 1}))
	}

	const goToNextPage = () => {
		if (giftLimitFilterParameters?.pageIndex + 1 >= giftLimitFilterParameters?.totalPages) return
		setFilters(prev => ({...prev, page: giftLimitFilterParameters?.pageIndex + 2}))
	}

	const goToPage = (index) => {
		setFilters(prev => ({...prev, page: index + 1}))
	}

	let paginationAllFunctionsList = {
		goToPrevPage: goToPrevPage,
		goToNextPage: goToNextPage,
		goToPage: goToPage,
		usageData: giftLimitFilterParameters,
	}

	const sortTable = (e, column) => {
		e.preventDefault()
		e.stopPropagation()
		setFilters(prev => ({...prev, sortingColumnName: column.name, sortingOrderByVal: prev.sortingOrderByVal == "asc" ? 'desc' : 'asc'}))
	}

	const handleFilterChange = (filterName, value) => {
		setFilters({ ...filters, [filterName]: value })
	}

	return (
		<div className='home'>
			{/* <Profile/> */}
			<SwitchComp dateFilter={dateFilter} setDateFilter={setDateFilter} switchOpen={switchOpen} setSwitchOpen={setSwitchOpen} />
			<div className='home-lineChart-buttonContainer'>
			<div className='home-lineChart-buttonGroup'>
                 <div onClick={() => setLineFilter(0)} className={`home-lineChart-buttonGroup-button ${lineFilter == 0 ? "active" : ""}`}>{t('day')}</div>
                 <div onClick={() => setLineFilter(1)} className={`home-lineChart-buttonGroup-button ${lineFilter == 1 ? "active" : ""}`}>{t('week')}</div>
                 <div onClick={() => setLineFilter(2)} className={`home-lineChart-buttonGroup-button ${lineFilter == 2 ? "active" : ""}`}>{t('month')}</div>
			</div>
			<div className='home-lineChart-buttonGroup'>
			   <div onClick={() => setPieceAndAmountFilter(0)} className={`home-lineChart-buttonGroup-button ${pieceAndAmountFilter == 0 ? "active" : ""}`}>{t('byUnit')}</div>
               <div onClick={() => setPieceAndAmountFilter(1)} className={`home-lineChart-buttonGroup-button ${pieceAndAmountFilter == 1 ? "active" : ""}`}>{t('byAmount')}</div>
			</div>
			</div>
			
			<Chart data={chartData1} type={'area'} />
			<Cards pieceAndAmountFilter={pieceAndAmountFilter} setReportCommonStats={setReportCommonStats} reportCommonStats={reportCommonStats} switchOpen={switchOpen} />
		    <div className='home-multiChart'>
				<Chart data={chartData2} type={'bar'} />
				<Chart data={chartData3} type={'bar'} />
				<Chart data={chartData4} type={'bar'} />
				<Chart data={chartData5} type={'bar'} />
			</div>

			{(currentUser?.permissions[permissionTypes.GiftsViewAllCompanies] || currentUser?.permissions[permissionTypes.GiftsViewOwnCompany]) && <div className='tableCard incoming-gift home-report'>
			<div className='home-report-title'>{t('giftLimit')}</div>
            <div className='home-report-buttonContainer'>
			<Button
				onclick={handleDownloadXlsx}
				name={t('exportAsExcel')}
				img={images.exit}
				color='#008814'
				backgroundColor='transparent'
				height='38px'
			/>
			</div>
                 <Table
					filterType={10}
					gridCSS='gift-limit-home'
					paginationAllFunctionsList={paginationAllFunctionsList}
					sortTable={sortTable}
					data={data}
					columns={COLUMNS}
					handleFilterChange={handleFilterChange}
					setFilters={setFilters}
					filters={filters}
					isLoading={loading}
				/>

			</div>}
		</div>
	)
}

export default Home
