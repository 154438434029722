/*eslint-disable*/
import images from 'assets/images/images'
import Button from 'components/common/Button'
import Input from 'components/common/Input'
import Loading from 'components/common/Loading'
import SelectDr from 'components/common/SelectDr'
import Title from 'components/common/Title'
import Table from 'components/common/table/Table'
import Modal from 'containers/modal/Modal'
import { useRequest } from 'hooks/useRequest'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AiOutlineCheckSquare, AiOutlineCloseSquare  } from 'react-icons/ai'
import { FaChevronDown, FaChevronRight, FaRegEdit } from 'react-icons/fa'
import { MdOutlineCheckBox, MdOutlineCheckBoxOutlineBlank, MdOutlineDeleteSweep } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getCurrencies, getCurrenyFilterParametersFunc } from 'redux/features/dataManagement/adminPanel/currencySlice'
import { getLocationGroupSelectListFunc } from 'redux/features/dataManagement/adminPanel/locationGroupSlice'
import { getUserManagement, getUserManagementFilterParametersFunc } from 'redux/features/dataManagement/adminPanel/userManagementSlice'
import { openModal } from 'redux/features/general/generalSlice'
import { permissionTypes } from 'types/permissionTypes'
import { downloadFileFromStream } from 'utils/ExcelOutput'
import CheckboxTree from 'react-checkbox-tree'
import { RiCheckboxMultipleFill } from 'react-icons/ri'


const UserManagement = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const [request, loading] = useRequest()
	const { modals } = useSelector((state) => state.general)
	const { adminPanel } = useSelector((state) => state.userManagement)

	const { modalId, modalStatus, modalToggle } = modals
	const { userManagement, userManagementFilterParameters } = adminPanel

	const { permissions } = useSelector((state) => state.configuration)
	const { currentUser } = permissions.configurations

	const navigate = useNavigate()

	const selectRef = useRef(null)

	const [tabModal, setTabModal] = useState(0)
	const [roleManagementPermissions, setRoleManagementPermissions] = useState([])
	const [hierarchicalTreeList, setHierarchicalTreeList] = useState([])
	const [treeChecked, setTreeChecked] = useState([])
	const [treeExpanded, setTreeExpanded] = useState([])
	const [hierarchicalList, setHierarchicalList] = useState([])

	const mapTreeNodes = (items) => {
		return items?.map((prev) => ({
			value: prev.id,
			label: prev.name,
			children: prev?.children?.length > 0 ? mapTreeNodes(prev.children) : null,
		}))
	}

	let treeNodes = mapTreeNodes(hierarchicalList)

	const [userManagementAddingUsername, setUserManagementAddingUsername] = useState()
	const [userManagementAddingName, setUserManagementAddingName] = useState()
	const [userManagementAddingSurname, setUserManagementAddingSurname] = useState()
	const [userManagementAddingEmail, setUserManagementAddingEmail] = useState()
	const [userManagementAddingRoles, setUserManagementAddingRoles] = useState()
	const [userManagementAddingSubstitute, setUserManagementAddingSubstitute] = useState()
	const [userManagementAddingPassword, setUserManagementAddingPassword] = useState()
	const [userManagementAddingConfirmPassword, setUserManagementAddingConfirmPassword] = useState()
	const [userManagementAddingCompanyId, setUserManagementAddingCompanyId] = useState()
	const [userManagementAddingManagerId, setUserManagementAddingManagerId] = useState()

	const [statusChecked, setStatusChecked] = useState(0)
	const [isManagerChecked, setIsManagerChecked] = useState(false)

	const [companySelectList, setCompanySelectList] = useState()
	const [userSelectList, setUserSelectList] = useState()
	const [rolesSelectList, setRolesSelectList] = useState()
	const [substituteUserSelectList, setSubstituteUserSelectList] = useState()

	//table filter area
	const [filters, setFilters] = useState({
		companyId: '',
		search: '',		
		sortingOrderByVal: "desc",
		sortingColumnName: "",
		page: 1,
	})


	const handleRemove = (item) => {
		dispatch(
			openModal({
				open: true,
				type: { modalId: null, modalStatus: 'adminPanel-deleteModal', editableData: { ...item, deleteItem: 'userManagement' } },
			})
		)
	}
	const handleEdit = (item) => {
		request(`/identity/user/${item.id}`, {
			method: 'GET',
		}).then((res) => {
			if (res.data.ok) {
				dispatch(
					openModal({
						open: true,
						type: {
							modalId: null,
							modalStatus: 'adminPanel-editModal',
							editableData: {
								...res.data.result,
								treeNodes: treeNodes,
								treeChecked: treeChecked,
								treeExpanded: treeExpanded,
								companySelectList: companySelectList,
								rolesSelectList: rolesSelectList,
								// substituteUserSelectList: substituteUserSelectList,
								// userSelectList: userSelectList,
								choiceManagerSelectList: {
									value: res?.data?.result?.managerId,
									label: res?.data?.result?.managerFullName,
								},
								choiceCompanySelectList: {
									value: res?.data?.result?.companyId,
									label: companySelectList?.find((compSelectList) => compSelectList?.name == res?.data?.result?.companyId)?.value,
								},
								choiceRolesSelectList: rolesSelectList
									.filter((role) => res?.data?.result?.roles?.includes(role.name))
									.map((dt) => ({ value: dt.name, label: dt.value })),
								choiceSubstituteUserList: res?.data?.result?.substitutedUsers,	
								editItem: 'userManagement',
							},
						},
					})
				)
			}
		})
	}


	const COLUMNS = [
		{
			label: t('username'),
			renderCell: (item) => item.userName,
			name: 'userName',
		},
		{
			label: t('name'),
			renderCell: (item) => item.fullName,
			name: 'name',
		},
		{
			label: t('companyName'),
			renderCell: (item) => item.companyName,
			name: 'companyName',
		},
		{
			label: t('manager'),
			renderCell: (item) =>
				item.isManager ? <AiOutlineCheckSquare  color='green' size={27}/> : <AiOutlineCloseSquare  size={27} color='#c1312f'/>,
			name: 'isManager',
		},
		{
			label: t('managerName'),
			renderCell: (item) => item.managerFullName,
			name: '',
		},
		{
			label: t('roles'),
			renderCell: (item) => item.roleNames.map((role, i) => (i == item.roleNames.length - 1 ? role : `${role}, `)),
			name: '', // burayı incele ...
		},

		{
			label: '',
			renderCell: (item) => (
				<div className='lastCellFlex'>
					<div className={`cabin-semibold ${item.status == 1 ? 'tableActiveStatus' : 'tablePassiveStatus'}`}>
						{item.status == 1 ? t('active') : t('passive')}
					</div>
					{currentUser?.permissions[permissionTypes.UsersUpsert] && (
						<div className='editBtn' onClick={(e) => e.stopPropagation()}>
							<FaRegEdit size={15} onClick={() => handleEdit(item)} />
							<div onClick={() => handleEdit(item)} className=''>
							{t('edit')}
							</div>
						</div>
					)}
					{currentUser?.permissions[permissionTypes.UsersDelete] && (
						<div className='deleteBtn' onClick={(e) => e.stopPropagation()}>
							<MdOutlineDeleteSweep size={18} onClick={() => handleRemove(item)} />
							<div onClick={() => handleRemove(item)} className=''>
							{t('delete')}
							</div>
						</div>
					)}
				</div>
			),
			name: '',
		},
	]

	useEffect(() => {
		request(`/company/select-list-hierarchical`, {
			method: 'GET',
		}).then((res) => {
			setHierarchicalList(res.data.result)
		})
	},[])

	useEffect(() => {
        setFilters(prev => ({...prev, page: 1}))
	}, [filters.companyId, filters.search, filters.sortingColumnName, filters.sortingOrderByVal])

	useEffect(() => {
		// ?PageSize=15
		request(`/identity/user?Page=${filters?.page}&CompanyId=${filters.companyId}&Search=${filters.search}&OrderBy=${filters.sortingColumnName ? filters.sortingColumnName + " "+ filters.sortingOrderByVal : ""}`, {
			method: 'GET',
		}).then((res) => {
			if (res.data.ok) {
				let filterPrm = {
					pageIndex: res.data.result.pageIndex,
					pageSize: res.data.result.pageSize,
					totalCount: res.data.result.totalCount,
					totalPages: res.data.result.totalPages,
					indexFrom: res.data.result.indexFrom,
				}
				dispatch(getUserManagementFilterParametersFunc(filterPrm))
				dispatch(getUserManagement(res.data.result.items))
			}
		})
	}, [filters])

	useEffect(() => {
		request('/company/select-list?export=true', {
			method: 'GET',
		}).then((res) => {
			if (res.data.ok) {
				setCompanySelectList(res.data.result.items)
			}
		})
		request('/identity/role/select-list?export=true', {
			method: 'GET',
		}).then((res) => {
			if (res.data.ok) {
				setRolesSelectList(res.data.result.items)
			}
		})

	}, [])

	useEffect(() => {
		
		if (userManagementAddingCompanyId?.value) {
			request(`/identity/user/select-list?Export=true&OnlyManager=true&CompanyId=${userManagementAddingCompanyId.value}`, {
				method: 'GET',
			}).then((res) => {
				if (res.data.ok) {
					setUserSelectList(res.data.result.items)
				}
			})	
			
			request(`/identity/user/select-list?Export=true&CompanyId=${userManagementAddingCompanyId?.value}`, {
				method: 'GET',
			}).then((res) => {
				if (res.data.ok) {
					setSubstituteUserSelectList(res.data.result.items)
				}
			})
		}
	}, [userManagementAddingCompanyId])

	let nodes = userManagement

	let data = { nodes }

	const handleDownloadXlsx = () => {
		request(`/identity/user/export-excel?Page=${filters?.page}&CompanyId=${filters.companyId}&Search=${filters.search}&OrderBy=${filters.sortingColumnName ? filters.sortingColumnName + " "+ filters.sortingOrderByVal : ""}`, {
			method: 'GET',
		}).then((res) => {
			if (res.data.ok) {
				downloadFileFromStream('currency.xlsx', res.data.result)
			}
		})
	}


	const userAddBtn = () => {
		request(`/identity/user`, {
			method: 'POST',
			data: {
				name: userManagementAddingName,
				surname: userManagementAddingSurname,
				userName: userManagementAddingUsername,
				email: userManagementAddingEmail,
				roles: userManagementAddingRoles?.map((prev) => prev.value),
				substitutedUsers:userManagementAddingSubstitute?.map((prev) => prev.value),
				companyId: userManagementAddingCompanyId?.value,
				managerId: userManagementAddingManagerId?.value,
				password: userManagementAddingPassword,
				passwordConfirm: userManagementAddingConfirmPassword,
				status: statusChecked,
				isManager: isManagerChecked,
				companies: hierarchicalTreeList
			},
		}).then((res) => {
			if (res.data.ok) {
				//dispatch(addGiftType(res.data.result))
				request('/identity/user', {
					method: 'GET',
				}).then((res) => {
					if (res.data.ok) {
						let filterPrm = {
							pageIndex: res.data.result.pageIndex,
							pageSize: res.data.result.pageSize,
							totalCount: res.data.result.totalCount,
							totalPages: res.data.result.totalPages,
							indexFrom: res.data.result.indexFrom,
						}
						dispatch(getUserManagementFilterParametersFunc(filterPrm))
						dispatch(getUserManagement(res.data.result.items))
					}
				})
			}
			toast.success('Ekleme işlemi başarılı')
			setTimeout(() => {
				dispatch(openModal({ open: false, type: { modalId: null, modalStatus: null } }))
				setUserManagementAddingCompanyId()
				setUserManagementAddingManagerId()
				setUserManagementAddingConfirmPassword()
				setUserManagementAddingEmail()
				setUserManagementAddingName()
				setUserManagementAddingPassword()
				setUserManagementAddingRoles()
				setUserManagementAddingSubstitute()
				setUserManagementAddingSurname()
				setUserManagementAddingUsername()
			}, 100)
		})
	}

	const goToPrevPage = () => {
		if (userManagementFilterParameters?.pageIndex == 0) return
		setFilters(prev => ({...prev, page: userManagementFilterParameters?.pageIndex - 1}))
	}
	const goToNextPage = () => {
		if (userManagementFilterParameters?.pageIndex + 1 >= userManagementFilterParameters?.totalPages) return
		setFilters(prev => ({...prev, page: userManagementFilterParameters?.pageIndex + 2}))
	}

	const goToPage = (index) => {
		setFilters(prev => ({...prev, page: index + 1}))
	}

	let paginationAllFunctionsList = {
		goToPrevPage: goToPrevPage,
		goToNextPage: goToNextPage,
		goToPage: goToPage,
		usageData: userManagementFilterParameters,
	}

	const sortTable = (e, column) => {
		e.preventDefault()
		e.stopPropagation()
		setFilters(prev => ({...prev, sortingColumnName: column.name, sortingOrderByVal: prev.sortingOrderByVal == "asc" ? 'desc' : 'asc'}))
	}

	const handleFilterChange = (filterName, value) => {
		setFilters({ ...filters, [filterName]: value })
	}


	useEffect(() => {
		setHierarchicalTreeList(treeChecked)
	}, [treeChecked])

	return (
		<div className='currency panel-table location-group incoming-gift'>
			<div className='topButtonGroup'>
				<div className='topButtonGroup-container'>
					{/* <Loading width="20px" height="20px" wholePage={false} borderWidth = "3px"/> */}
					<Button
						onclick={handleDownloadXlsx}
						name={t('exportAsExcel')}
						img={images.exit}
						color='#008814'
						backgroundColor='transparent'
						height='38px'
					/>
					{currentUser?.permissions[permissionTypes.UsersUpsert] && (
						<Button
							onclick={() => dispatch(openModal({ open: true, type: { modalId: 3, modalStatus: 'userManagement' } }))}
							name={t('addUserManagement')}
							img={images.plus}
							color='#fff'
							backgroundColor='#1e74ad'
							height='38px'
						/>
					)}
				</div>
			</div>

			<div className='tableCard'>
				<Table
					gridCSS='userManagement'
					deleteBtn={true}
					sortTable={sortTable}
					paginationAllFunctionsList={paginationAllFunctionsList}
					data={data}
					columns={COLUMNS}
					filterType={5}
					handleFilterChange={handleFilterChange}
					setFilters={setFilters}
					isLoading={loading}
				/>
			</div>

			{/* .incoming-gift.panel-table .table */}

			{modalId == 3 && modalToggle && modalStatus == 'userManagement' && (
				// <Modal customModal={'userModal'}>
				// 	<div className='modal-type1 modal-type1-changed'>
				// 		<Title name={t('addUserManagement')} color='#242424' fontweight='bold' size='24px' />

				// 		<div style={{ marginTop: '10px' }} className='userManagement-addingModal'>
				// 			<Input value={userManagementAddingName} setValue={setUserManagementAddingName} labelColor='#292929' label={t('name')} type='text' />
				// 			<Input
				// 				value={userManagementAddingSurname}
				// 				setValue={setUserManagementAddingSurname}
				// 				labelColor='#292929'
				// 				label={t('surname')}
				// 				type='text'
				// 			/>
				// 		</div>
				// 		<div className='userManagement-addingModal'>
				// 			<Input
				// 				value={userManagementAddingUsername}
				// 				setValue={setUserManagementAddingUsername}
				// 				labelColor='#292929'
				// 				label={t('username')}
				// 				type='text'
				// 			/>
				// 			<Input value={userManagementAddingEmail} setValue={setUserManagementAddingEmail} labelColor='#292929' label={'Email'} type='text' />
				// 		</div>

				// 		<div className='userManagement-addingModal'>
				// 			<Input
				// 				value={userManagementAddingPassword}
				// 				setValue={setUserManagementAddingPassword}
				// 				labelColor='#292929'
				// 				label={t('password')}
				// 				type='text'
				// 			/>
				// 			<Input
				// 				value={userManagementAddingConfirmPassword}
				// 				setValue={setUserManagementAddingConfirmPassword}
				// 				labelColor='#292929'
				// 				label={t('confirmPassword')}
				// 				type='text'
				// 			/>
				// 		</div>

				// 		<div className='userManagement-addingModal'>
				// 			<SelectDr
				// 				ref={selectRef}
				// 				// onChange={(value) => setLocationGroupEditStates((prev) => ({ ...prev, parentId: value ? value : '' }))}
				// 				onChange={(value) => setUserManagementAddingRoles(value ? value : '')}
				// 				//(value) => setLocationGroupAddingParentName(value ? value : '')
				// 				//defaultValue={{ value: editableData?.parentId, label: editableData?.parentName }}
				// 				type={1}
				// 				isLoading={loading}
				// 				options={rolesSelectList?.map((prev) => ({ value: prev.name, label: prev.value }))}
				// 				placeholder={''}
				// 				label={t('roles')}
				// 			/>
				// 			<SelectDr
				// 				ref={selectRef}
				// 				// onChange={(value) => setLocationGroupEditStates((prev) => ({ ...prev, parentId: value ? value : '' }))}
				// 				onChange={(value) => setUserManagementAddingCompanyId(value ? value : '')}
				// 				//defaultValue={{ value: editableData?.parentId, label: editableData?.parentName }}
				// 				type={0}
				// 				isLoading={loading}
				// 				options={companySelectList?.map((prev) => ({ value: prev.name, label: prev.value }))}
				// 				placeholder={''}
				// 				label={t('companyName')}
				// 			/>
				// 		</div>
				// 		<div className='userManagement-addingModal'>
				// 			<SelectDr
				// 				ref={selectRef}
				// 				// onChange={(value) => setLocationGroupEditStates((prev) => ({ ...prev, parentId: value ? value : '' }))}
				// 				onChange={(value) => setUserManagementAddingManagerId(value ? value : '')}
				// 				//defaultValue={{ value: editableData?.parentId, label: editableData?.parentName }}
				// 				type={0}
				// 				isLoading={loading}
				// 				options={userSelectList?.map((prev) => ({ value: prev.name, label: prev.value }))}
				// 				placeholder={''}
				// 				label={t('thismanager')}
				// 			/>
				// 		</div>

				// 		<div className='modal-type1-checkArea'>
				// 			<input onChange={(e) => setStatusChecked(e.target.checked ? 1 : 0)} type='checkbox' name='' id='' />
				// 			<div>{t('active')}</div>
				// 		</div>
				// 		<div style={{ marginTop: '-10px' }} className='modal-type1-checkArea'>
				// 			<input onChange={(e) => setIsManagerChecked(e.target.checked)} type='checkbox' name='' id='' />
				// 			<div>{t('manager')}</div>
				// 		</div>
				// 		<div className='modal-type1-buttonGroup'>
				// 			<Button
				// 				onclick={() => dispatch(openModal({ open: false, type: { modalId: null, modalStatus: null } }))}
				// 				name={t('cancel')}
				// 				color='#fff'
				// 				backgroundColor='#c1312f'
				// 				height='48px'
				// 			/>
				// 			<Button onclick={userAddBtn} name={t('add')} color='#fff' backgroundColor='#1392e5' height='48px' />
				// 		</div>
				// 	</div>
				// </Modal>

				<Modal customModal={'userModal'}>
					<div className='tab-modal'>
						<div className='tab-modal-lgTitle'>
							<Title name={t('addUserManagement')} color='#242424' fontweight='bold' size='24px' />
						</div>
						<div className='tab-modal-titles'>
							<div className={tabModal == 0 ? 'active' : ''} onClick={() => setTabModal(0)}>
							{t('user')}
							</div>
							<div className={tabModal == 1 ? 'active' : ''} onClick={() => setTabModal(1)}>
							{t('companies')}
							</div>
						</div>
						{tabModal == 0 ? (
						<div className='modal-type1 modal-type1-changed'>

							<div style={{ marginTop: '-10px' }} className='userManagement-addingModal'>
								<Input value={userManagementAddingName} setValue={setUserManagementAddingName} labelColor='#292929' label={t('name')} type='text' />
								<Input
									value={userManagementAddingSurname}
									setValue={setUserManagementAddingSurname}
									labelColor='#292929'
									label={t('surname')}
									type='text'
								/>
							</div>
							<div className='userManagement-addingModal'>
								<Input
									value={userManagementAddingUsername}
									setValue={setUserManagementAddingUsername}
									labelColor='#292929'
									label={t('username')}
									type='text'
								/>
								<Input value={userManagementAddingEmail} setValue={setUserManagementAddingEmail} labelColor='#292929' label={'Email'} type='text' />
							</div>

							<div className='userManagement-addingModal'>
								<Input
									value={userManagementAddingPassword}
									setValue={setUserManagementAddingPassword}
									labelColor='#292929'
									label={t('password')}
									type='text'
								/>
								<Input
									value={userManagementAddingConfirmPassword}
									setValue={setUserManagementAddingConfirmPassword}
									labelColor='#292929'
									label={t('confirmPassword')}
									type='text'
								/>
							</div>

							<div className='userManagement-addingModal'>
								<SelectDr
									ref={selectRef}
									// onChange={(value) => setLocationGroupEditStates((prev) => ({ ...prev, parentId: value ? value : '' }))}
									onChange={(value) => setUserManagementAddingRoles(value ? value : '')}
									//(value) => setLocationGroupAddingParentName(value ? value : '')
									//defaultValue={{ value: editableData?.parentId, label: editableData?.parentName }}
									type={1}
									isLoading={loading}
									options={rolesSelectList?.map((prev) => ({ value: prev.name, label: prev.value }))}
									placeholder={''}
									label={t('roles')}
								/>
								<SelectDr
									ref={selectRef}
									// onChange={(value) => setLocationGroupEditStates((prev) => ({ ...prev, parentId: value ? value : '' }))}
									onChange={(value) => setUserManagementAddingCompanyId(value ? value : '')}
									//defaultValue={{ value: editableData?.parentId, label: editableData?.parentName }}
									type={0}
									isLoading={loading}
									options={companySelectList?.map((prev) => ({ value: prev.name, label: prev.value }))}
									placeholder={''}
									label={t('companyName')}
								/>
							</div>
							<div className='userManagement-addingModal'>
								<SelectDr
									ref={selectRef}
									// onChange={(value) => setLocationGroupEditStates((prev) => ({ ...prev, parentId: value ? value : '' }))}
									onChange={(value) => setUserManagementAddingManagerId(value ? value : '')}
									//defaultValue={{ value: editableData?.parentId, label: editableData?.parentName }}
									type={0}
									isLoading={loading}
									options={userSelectList?.map((prev) => ({ value: prev.name, label: prev.value }))}
									placeholder={''}
									label={t('thismanager')}
								/>
								<SelectDr
									ref={selectRef}
									// onChange={(value) => setLocationGroupEditStates((prev) => ({ ...prev, parentId: value ? value : '' }))}
									onChange={(value) => setUserManagementAddingSubstitute(value ? value : '')}
									//(value) => setLocationGroupAddingParentName(value ? value : '')
									//defaultValue={{ value: editableData?.parentId, label: editableData?.parentName }}
									type={1}
									isLoading={loading}
									options={substituteUserSelectList?.map((prev) => ({ value: prev.name, label: prev.value }))}
									placeholder={''}
									label={t('proxyUser2')}
								/>
							</div>

							<div className='modal-type1-checkArea'>
								<input onChange={(e) => setStatusChecked(e.target.checked ? 1 : 0)} type='checkbox' name='' id='' />
								<div>{t('active')}</div>
							</div>
							<div style={{ marginTop: '-10px' }} className='modal-type1-checkArea'>
								<input onChange={(e) => setIsManagerChecked(e.target.checked)} type='checkbox' name='' id='' />
								<div>{t('manager')}</div>
							</div>
							
						</div>
						) : (
							<div className='tab-modal-tab2'>
								<CheckboxTree
									nodes={treeNodes}
									noCascade={true}
									checked={treeChecked}
									expanded={treeExpanded}
									onCheck={(checked, targetNode) => {
										checked = checked.map(v => parseInt(v))
										if(targetNode?.checked && targetNode.isParent) {
											targetNode.children?.forEach(child => {
												if(!checked.includes(child.value)) {
													checked.push(child.value)
												}
											});

											if(!treeExpanded?.includes(targetNode.value)) {
												setTreeExpanded(prev => [...prev, targetNode.value])
											}
										}

										setTreeChecked(checked)
									}}
									onExpand={(expanded) => {
										expanded = expanded.map(v => parseInt(v))
										setTreeExpanded(expanded)
									}}
									//iconsClass="fa5"
									icons={{
										check: <MdOutlineCheckBox size={24} />,
										uncheck: <MdOutlineCheckBoxOutlineBlank size={24} />,
										leaf: <RiCheckboxMultipleFill />,
										expandClose: <FaChevronRight />,
										expandOpen: <FaChevronDown />,
									}}
									//expandIconRenderer={renderExpandIcon}
								/>
							</div>
						)}
						<div className='tab-modal-modalGroup'>
							<Button onclick={() => dispatch(openModal({ open: false, type: { modalId: null, modalStatus: null } }))} name={t('cancel')} color='#fff' backgroundColor='#c1312f' height='48px' />
							<Button onclick={userAddBtn} name={t('add')} color='#fff' backgroundColor='#1392e5' height='48px' />
						</div>
					</div>
				</Modal>
			)}
		</div>
	)
}

export default UserManagement
