import React from 'react'
import { PropTypes } from 'prop-types'
import { debounce } from 'lodash';

const Input = ({labelColor, type, value, defaultValue, setValue, placeholder, label, errorBorder, multipleState, name, readOnly, style, addDebounce}) => {
  const inputLabelStyle = {
    color : labelColor,
      fontSize: "13px",
      marginBottom: "8px",
      ...style

  }

  const inputChangeFunc = (e, multipleStateControl) => {
    if(multipleStateControl){
      setValue(prev => ({
           ...prev, [e.target.name] : e.target.value
      }))
    }else{
      setValue(e.target.value)
    }
  }


  const handleChange = addDebounce ? debounce(inputChangeFunc, 400) : inputChangeFunc;

  return (
    <div className='searchInputContainer'>
    <label style={inputLabelStyle} htmlFor="">{label}</label>
    <input 
        readOnly={readOnly}
        name={name}
        className={`searchInput ${errorBorder ? "errorBorder" : ""}`} 
        placeholder={placeholder} 
        autoComplete="off" 
        type={type} 
        value={value} 
        defaultValue={defaultValue}
        onChange={e => handleChange(e, multipleState)}
    />
    </div>
  )
}

Input.propTypes = {
	type: PropTypes.string,
	labelColor: PropTypes.string,
	value: PropTypes.string,
  defaultValue: PropTypes.string,
	label: PropTypes.string,
	placeholder: PropTypes.string,
  setValue: PropTypes.func,
  errorBorder: PropTypes.bool,
  multipleState: PropTypes.bool,
  name: PropTypes.string,
  readOnly: PropTypes.bool,
  style: PropTypes.object,
  addDebounce: PropTypes.bool
}

export default Input