import images from 'assets/images/images';
import Accordion from 'components/common/Accordion'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { openAccordion } from 'redux/features/general/generalSlice';
import { useTranslation } from 'react-i18next';

const SidebarBottom = () => {
    const dispatch = useDispatch();
    const {accordions} = useSelector(state => state.general)
    const { politikalar, teknikdestek} = accordions
    const { t } = useTranslation();
    const accordionList = [
        
        // {
        //     id: 'etikhat',
        //     name: "Etik Hat",
        //     open: etikhat,
        //     type: 0,
        //     content: [
        //         {
        //             name: "Etik İlkeler",
        //             url: "/"
        //         },
        //         {
        //             name: "Hediye ve Ağırlama Politikası",
        //             url: "/"
        //         },
        //         {
        //             name: "Diğer Politikalar",
        //             url: "/"
        //         },
        //     ]
        // },
        {
            id: 'politikalar',
            name: t('policies'),
            open: politikalar,
            type: 0,
            content: [
                {
                    name: t('ethicalPrinciples'),
                    url: localStorage.getItem('language') == "tr" ? "https://cdn.koc.com.tr/cmscontainer/kocholding/media/koc/01hakkinda/uyum/2024/tr/koc-toplulugu-etik-ilkeleri-2023.pdf" : "https://cdn.koc.com.tr/cmscontainer/kocholding/media/koc/01hakkinda/uyum/2024/en/koc-group-code-of-ethics-2023.pdf"
                },
                {
                    name: t('giftandHospitalityPolicy'),
                    url: localStorage.getItem('language') == "tr" ? "https://cdn.koc.com.tr/cmscontainer/kocholding/media/koc/01hakkinda/uyum/2024/tr/koc-toplulugu-hediye-ve-agirlama-politikasi-2023.pdf" : "https://cdn.koc.com.tr/cmscontainer/kocholding/media/koc/01hakkinda/uyum/2024/en/koc-group-gifts-and-entertainment-policy-2023.pdf"
                },
                {
                    name: t('otherPolicies'),
                    url: localStorage.getItem('language') == "tr" ? "https://www.koc.com.tr/hakkinda/etik-ilkeler-ve-uyum-politikalari" : "https://www.koc.com.tr/about-us/code-of-ethics-and-compliance-policies"
                },
            ]
        },
        {
            id: 'teknikdestek',
            name: t('technicalSupport'),
            open: teknikdestek,
            type: 1,
            content: [
                {
                    img: images.phone,
                    name: "0850 399 22 33",
                    url: "",
                    noClick: true
                },
                {
                    img: images.wp,
                    name: "Whatsapp",
                    url: "https://api.whatsapp.com/send/?phone=908503992233&text&type=phone_number&app_absent=0"
                },
                {
                    img: images.mail,
                    name: "cozum@kocweb.com",
                    url: "mailto:cozum@kocweb.com"
                },
                {
                    img: images.comment,
                    name: "Web Chat",
                    url: "/",
                    clickFunc: true
                },
            ]
        },
    ]

    return (
    <div className='sidebar-bottom cabin-bold'>
           <div className='sidebar-bottom-link'>
             <a rel="noreferrer" target="_blank" href={localStorage.getItem('language') == "tr" ? "https://secure.ethicspoint.eu/domain/media/tr/gui/108227/" : "https://secure.ethicspoint.eu/domain/media/en/gui/108227/index.html"}>{t('ourEthics')}</a>
           </div>
        {
            accordionList.map((accordion, i) => (
              <Accordion key={i} onclick={() => dispatch(openAccordion(accordion.id))} title={accordion.name} open={accordion.open} content={accordion.content} type={accordion.type}/>
            ))
        }
    </div>
  )
}

export default SidebarBottom