/* eslint-disable */
import images from 'assets/images/images'
import Button from 'components/common/Button'
import Table from 'components/common/table/Table'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { usePagination } from '@table-library/react-table-library/pagination'
import { useSort } from '@table-library/react-table-library/sort'
import * as XLSX from 'xlsx'
import { MdOutlineDeleteSweep } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import { useRequest } from 'hooks/useRequest'
import { openModal } from 'redux/features/general/generalSlice'
import moment from 'moment'
import { downloadFileFromStream } from 'utils/ExcelOutput'
import { getOutgoingGift, getOutgoingGiftFilterParametersFunc } from 'redux/features/dataManagement/outgoingGift/outgoingGiftSlice'
import { permissionTypes } from 'types/permissionTypes'
import { AiOutlineCheckSquare, AiOutlineCloseSquare } from 'react-icons/ai'
import {Tooltip} from 'react-tooltip';

const CustomSortIcon = () => {
	return <img src={images.filterico} alt='' style={{ margin: '0px' }} />
}

const OutgoingGift = () => {
	const navigate = useNavigate()
	const { t, i18n } = useTranslation()
	const dispatch = useDispatch()
	const [request, loading] = useRequest()
	const { outgoingGiftObj } = useSelector((state) => state.outgoingGift)

	const { outgoingGift, outgoingGiftFilterParameters } = outgoingGiftObj

	const {permissions} = useSelector(state => state.configuration)
	const {currentUser} = permissions.configurations

	let substitutePage = window.location.pathname.split('/')[1] == "substitute-panel"

	const location = useLocation();
	const { filterParams } = location.state || {};

	//filter object
	const [filters, setFilters] = useState({
		search: '',
		approvalStatus: filterParams == 0 ? 1 : filterParams == 1 ? 2 : filterParams == 2 ? 0 : "",
		startDate: null,
		endDate: null,
		giftTypeId: '',
		giftRelatedPartyTypeId: '',
		giftPurposeId: '',	
		sortingOrderByVal: "desc",
		sortingColumnName: "giftDate",
		page: 1,
		waitingForMyApproval: false,
		department: ""
	})


	const handleRemove = (item) => {
		dispatch(
			openModal({
				open: true,
				type: { modalId: null, modalStatus: 'adminPanel-deleteModal', editableData: { ...item, deleteItem: 'outgoing-gift' } },
			})
		)
	}

	const approveStatus = (status) => {
		switch (status) {
			case 0:
				return (
					<div style={{ color: '#DEC600', display: 'flex', alignItems: 'center', gap: '5px' }}>
						<img src={images.warningRounded} alt='' />
						<div>{t('AwaitingApproval')}</div>
					</div>
				)
			case 1:
				return (
					<div style={{ color: '#08A52B', display: 'flex', alignItems: 'center', gap: '5px' }}>
						<img src={images.successRounded} alt='' />
						<div>{t('approved')}</div>
					</div>
				)
			case 2:
				return (
					<div style={{ color: '#c1312f', display: 'flex', alignItems: 'center', gap: '5px' }}>
						<img src={images.errorRounded} alt='' />
						<div>{t('rejected')}</div>
					</div>
				)
			default:
				return (
					<div style={{ color: 'green', display: 'flex', alignItems: 'center', gap: '5px' }}>
						<img src={images.successRounded} alt='' />
						<div>{t('approved')}</div>
					</div>
				)
		}
	}

	const approvalOfficer = {
		0: t("system"),
		1: t("manager2"),
		2: t("complianceManager"),
		3: t("generalManager")
	} 

	const COLUMNS = [
		{
			label: t('companyName'),
			renderCell: (item) => item.companyName,
			name: 'companyName',
		},
		{
			label: t('submittedInstitutionName'),
			renderCell: (item) => <div style={{display: "flex", alignItems: "center", gap: "5px"}}>
			{item.targetCompanyName}
			{item.targetCompanyIsWaitingForApproval && 
			// <img title={t('notApprovedInstitution')} src={images.warningRounded} alt='' />
			<>
			<img
				data-tooltip-id="tooltip-info"
				data-tooltip-content={
				`${t('notApprovedInstitution')}`
				}
				src={images.warningRounded}
				alt=''
			/>
			<Tooltip id="tooltip-info" style={{ maxWidth: '100%' }} />
			</>
			}
		</div>,
			name: 'targetCompanyName',
		},
		// {
		// 	label: t('submittedInstitutionName'),
		// 	renderCell: (item) => item.targetCompanyName,
		// 	name: 'targetCompanyName',
		// },
		{
			label: t('CreatingUser'),
			renderCell: (item) => item.userFullName,
			name: 'userFullName',
		},
		{
			label: t('GiftRecipient'),
			renderCell: (item) => item.receiverName,
			name: 'receiverName',
		},
		{
			label: t('Amount'),
			renderCell: (item) => new Intl.NumberFormat(i18n.language, { style: 'currency', currency: item.currencyCode }).format(item.amount),
			name: 'amount',
			//sorting: (item) => item.amount
		},
		{
			label: t('GiftType'),
			renderCell: (item) => item.giftTypeName,
			name: '',
		},
		{
			label: t('PurposeoftheGift'),
			renderCell: (item) => item.giftPurposeName,
			name: '',
		},
		// {
		// 	label: t('waitingForMyApproval'),
		// 	renderCell: (item) =>
		// 	(item.complianceManagerApprovalDate && item.managerApprove && item.generalManagerApprove) ?	
		// 	item.waitingForMyApproval ? <AiOutlineCheckSquare  color='green' size={27}/> : <AiOutlineCloseSquare  size={27} color='#c1312f'/>: "",
		// 	name: 'waitingForMyApproval',
		// },
		{
			label: t('ApprovalStatus'),
			renderCell: (item) => approveStatus(item.approvalStatus),
			name: 'approvalStatus',
		},
		{
			label: t('approvalOfficer'),
			renderCell: (item) => approvalOfficer[item.approvalResponsible],
			name: '',
		},
		{
			label: t('GiftDate'),
			renderCell: (item) => new Date(item.giftDate).toLocaleDateString(i18n.language),
			name: "giftDate"
		},
		{
			label: '',
			renderCell: (item) => (
				currentUser?.permissions[permissionTypes.GiftsDelete] &&
				<div className='lastCellFlex'>
					<div className='deleteBtn' onClick={(e) => e.stopPropagation()}>
						<MdOutlineDeleteSweep size={18} onClick={() => handleRemove(item)} />
						<div onClick={() => handleRemove(item)} className=''>
						{t('delete')}
						</div>
					</div>
				</div>
			),
			name: '',
		},
	]

	useEffect(() => {
        setFilters(prev => ({...prev, page: 1}))
	}, [filters.approvalStatus, filters.search, filters.giftTypeId, filters.giftPurposeId, filters.startDate, filters.endDate, filters.sortingColumnName, filters.sortingOrderByVal, filters.waitingForMyApproval, filters.giftRelatedPartyTypeId])

	useEffect(() => {
		request(
			`/gift-transaction/outgoing?Page=${filters?.page}&Search=${filters?.search}&StartDate=${
				filters?.startDate ? moment(filters.startDate).format('YYYY-MM-DD') : ''
			}&OnlySubstitutedUsers=${substitutePage}&GiftRelatedPartyTypeId=${filters?.giftRelatedPartyTypeId}&Department=${filters?.department}&EndDate=${
				filters?.endDate ? moment(filters.endDate).format('YYYY-MM-DD') : ''
			}&ApprovalStatus=${filters?.approvalStatus}&WaitingForMyApproval=${filters?.waitingForMyApproval == ""  ? false : filters?.waitingForMyApproval}&GiftTypeId=${filters?.giftTypeId}&GiftPurposeId=${filters?.giftPurposeId}&OrderBy=${filters.sortingColumnName ? filters.sortingColumnName + " "+ filters.sortingOrderByVal : ""}`,
			{
				method: 'GET',
			}
		).then((res) => {
			if (res.data.ok) {
				let filterPrm = {
					pageIndex: res.data.result.pageIndex,
					pageSize: res.data.result.pageSize,
					totalCount: res.data.result.totalCount,
					totalPages: res.data.result.totalPages,
					indexFrom: res.data.result.indexFrom,
				}
				dispatch(getOutgoingGiftFilterParametersFunc(filterPrm))
				dispatch(getOutgoingGift(res.data.result.items))
			}
		})
	}, [filters])

	let nodes = outgoingGift

	let data = { nodes }

	const handleDownloadXlsx = () => {
		request(`/gift-transaction/outgoing/export-excel?Page=${filters?.page}&Search=${filters?.search}&StartDate=${
			filters?.startDate ? moment(filters.startDate).format('YYYY-MM-DD') : ''
		}&OnlySubstitutedUsers=${substitutePage}&GiftRelatedPartyTypeId=${filters?.giftRelatedPartyTypeId}&Department=${filters?.department}&EndDate=${
			filters?.endDate ? moment(filters.endDate).format('YYYY-MM-DD') : ''
		}&ApprovalStatus=${filters?.approvalStatus}&WaitingForMyApproval=${filters?.waitingForMyApproval == ""  ? false : filters?.waitingForMyApproval}&GiftTypeId=${filters?.giftTypeId}&GiftPurposeId=${filters?.giftPurposeId}&OrderBy=${filters.sortingColumnName ? filters.sortingColumnName + " "+ filters.sortingOrderByVal : ""}`, {
			method: 'GET',
		}).then((res) => {
			if (res.data.ok) {
				downloadFileFromStream('outgoing-gift.xlsx', res.data.result)
			}
		})
	}

	const handleFilterChange = (filterName, value) => {
		setFilters({ ...filters, [filterName]: value })
	}

	const goToPrevPage = () => {
		if (outgoingGiftFilterParameters?.pageIndex == 0) return
		setFilters(prev => ({...prev, page: outgoingGiftFilterParameters?.pageIndex - 1}))
		
	}

	const goToNextPage = () => {
		if (outgoingGiftFilterParameters?.pageIndex + 1 >= outgoingGiftFilterParameters?.totalPages) return
		setFilters(prev => ({...prev, page: outgoingGiftFilterParameters?.pageIndex + 2}))
	}

	const goToPage = (index) => {
		setFilters(prev => ({...prev, page: index + 1}))
	}

	let paginationAllFunctionsList = {
		goToPrevPage: goToPrevPage,
		goToNextPage: goToNextPage,
		goToPage: goToPage,
		usageData: outgoingGiftFilterParameters,
	}

	const sortTable = (e, column) => {
		e.preventDefault()
		e.stopPropagation()
		setFilters(prev => ({...prev, sortingColumnName: column.name, sortingOrderByVal: prev.sortingOrderByVal == "asc" ? 'desc' : 'asc'}))
	}

	return (
		<div style={{ height: '100%' }} className='incoming-gift company-hospitality'>
			<div className='topButtonGroup'>
				<div className='topButtonGroup-container'>
					<Button
						onclick={handleDownloadXlsx}
						name={t('exportAsExcel')}
						img={images.exit}
						color='#008814'
						backgroundColor='transparent'
						height='38px'
					/>
					{currentUser?.permissions[permissionTypes.GiftsCreate] && <Button
						onclick={() => substitutePage ? navigate('/substitute-panel/outgoing-gift/add') : navigate('/outgoing-gift/add')}
						name={t('addOutgoingGiftEntry')}
						img={images.plus}
						color='#fff'
						backgroundColor='#1e74ad'
						height='38px'
					/>}
				</div>
			</div>
			<div className='tableCard'>
				<Table
					filterType={1}
					gridCSS='outgoing-giftt'
					paginationAllFunctionsList={paginationAllFunctionsList}
					sortTable={sortTable}
					data={data}
					columns={COLUMNS}
					handleFilterChange={handleFilterChange}
					setFilters={setFilters}
					filters={filters}
					isLoading={loading}
					approvedFilterParams = {filterParams}
				/>
			</div>
		</div>
	)
}

export default OutgoingGift
